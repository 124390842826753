import { SpotInterface, VisibilityPageSectionEnabling } from 'types';

export const hasAccessToVisibilityPage = (spot: SpotInterface) =>
    spot.spot_config.visibility_page_section &&
    spot.spot_config.visibility_page_section !== VisibilityPageSectionEnabling.NONE;

export const hasAccessToHeroStats = (spot: SpotInterface) =>
    spot.spot_config.visibility_page_section === VisibilityPageSectionEnabling.STATS ||
    spot.spot_config.visibility_page_section === VisibilityPageSectionEnabling.ALL;

export const hasAccessToDriverLog = (spot: SpotInterface) =>
    spot.spot_config.visibility_page_section === VisibilityPageSectionEnabling.ALL;
